import create from 'zustand';
import {persist} from 'zustand/middleware';

const useStore = create(
  persist(
    set => ({
      map: false,
      filters: [],
      legend: false,
      numbers: false,
      images: false,
      hideMenus: false,
      set: values => set(s => ({...s, ...values})),
      toggle: key => set(s => ({...s, [key]: !s[key]})),
    }),
    {name: 'f1907'}
  )
);

export default useStore;
