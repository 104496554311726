import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import {useGlobalStyles} from './utils';

const Home = ({datas}) => {
  const classes = {...useGlobalStyles(), ...useStyles()};

  if (!datas) return null;
  const img = datas.image_logo[1];

  return (
    <Box
      position="absolute"
      top={0}
      left={0}
      width={1}
      height={1}
      minHeight={320}
      pt={2}
      pb={{xs: 2, md: 10}}
      px={{xs: 2, md: 12}}
      display="flex"
      flexDirection="column"
      className={classes.fadein}
    >
      <Box
        flexGrow={1}
        display="flex"
        justifyContent="center"
        alignItems="center"
        overflow="hidden"
      >
        {!!img && (
          <Box
            component="img"
            p={2}
            width={800}
            maxWidth={1}
            maxHeight={1}
            alt={img.origin.description}
            src={img.origin.httpUrl}
          />
        )}
      </Box>
      <Box pt={{xs: 2, md: 8}} borderTop={1}>
        {datas.text_description?.raw.split('\n').map((t, i) => (
          <Typography key={i} variant="h5" className={classes.bigtext}>
            {t}
          </Typography>
        ))}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  bigtext: {
    lineHeight: 1.1,
  },
}));

export default Home;
