import React, {useState, useEffect} from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import useStore from './useStore';
import SubNav from './SubNav';
import {read, getComponent} from './utils';

const Multiple = ({path, intro}) => {
  const [datas, setDatas] = useState();
  const set = useStore(s => s.set);

  useEffect(() => {
    (async () => setDatas(await read(path)))();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!datas) return;
    if (datas.checkbox_hide_menus) set({hideMenus: true});
  }, [datas, set]);

  if (!datas) return null;
  const pages = datas.children;
  const nav = !!datas.checkbox_navigation && !!pages;

  return (
    <>
      {nav ? <SubNav pages={pages.filter(page => !page.isHidden)} /> : <Box pt={10} />}
      {intro}
      {pages?.map(page => (
        <Box key={page.id} id={page.name}>
          {nav && !page.isHidden && (
            <Box px={{xs: 2, md: 12}} display="none" displayPrint="block">
              <Typography variant="h5" gutterBottom>
                {page.title}
              </Typography>
            </Box>
          )}
          {getComponent({
            ...page,
            section: true,
          })}
        </Box>
      ))}
    </>
  );
};

export default Multiple;
