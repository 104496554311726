import React, {useState, useEffect} from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import Multiple from './Multiple';
import {read, useGlobalStyles} from './utils';

const Post = page => {
  const {path} = page;
  const [datas, setDatas] = useState();
  const classes = useGlobalStyles();

  useEffect(() => {
    window.scrollTo(0, 0);

    (async () => setDatas(await read(`${path}?children=true`)))();
  }, []); // eslint-disable-line

  if (!datas) return null;

  return (
    <Multiple
      intro={
        <Box width={1} px={{xs: 2, md: 12}} className={classes.fadein}>
          <Box maxWidth={800} mb={4}>
            <Typography variant="h6">{datas.title}</Typography>
            <Typography variant="caption">{datas.date_post}</Typography>
          </Box>
        </Box>
      }
      {...datas}
    />
  );
};

export default Post;
