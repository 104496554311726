import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Fade from '@material-ui/core/Fade';
import ButtonBase from '@material-ui/core/ButtonBase';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import InfoIcon from '@material-ui/icons/Info';
import Popper from '@material-ui/core/Popper';
import clsx from 'clsx';
import {useTranslation} from 'react-i18next';

import useStore from './useStore';
import {borderT, useGlobalStyles} from './utils';
import theme from './theme';

const Filters = ({keywords, states}) => {
  const {t} = useTranslation();
  const filters = useStore(s => s.filters);
  const legend = useStore(s => s.legend);
  const numbers = useStore(s => s.numbers);
  const images = useStore(s => s.images);
  const set = useStore(s => s.set);
  const toggle = useStore(s => s.toggle);
  const [open, setOpen] = useState(false);
  const [popper, setPopper] = useState(null);
  const classes = {...useGlobalStyles(), ...useStyles({open})};

  useEffect(() => {
    if (!open) setPopper(null);
  }, [open]); // eslint-disable-line

  return (
    <Box position="sticky" top={0} displayPrint="none" className={classes.fadein}>
      <Box
        px={{xs: 2, md: 12}}
        py={2}
        height={theme.spacing(10)}
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <ButtonBase
          disableRipple
          onClick={() => setOpen(!open)}
          focusVisibleClassName={classes.focus}
          className={clsx(classes.hover, classes.features)}
        >
          <Typography className={classes.manier}>{t('temples.features').toUpperCase()}</Typography>
        </ButtonBase>
      </Box>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Fade in={open} {...(open ? {timeout: 1000} : {})}>
          <Box
            py={2}
            px={{xs: 2, md: 12}}
            display="flex"
            justifyContent="flex-end"
            flexWrap="wrap"
            overflow="hidden"
            {...borderT}
          >
            <Box
              display="flex"
              flexDirection="row"
              flexWrap="nowrap"
              alignItems="center"
              className={classes.tool}
            >
              <Typography className={clsx(classes.label, classes.manier)}>
                {t('temples.filters').toUpperCase()}
              </Typography>
              <Select
                multiple
                displayEmpty
                value={filters}
                onChange={e => set({filters: e.target.value})}
                input={<Input />}
                renderValue={selected =>
                  selected.length ? selected.join(', ') : <em>{t('temples.none')}</em>
                }
                MenuProps={{
                  PaperProps: {
                    style: {
                      width: 240,
                    },
                  },
                }}
                className={classes.select}
              >
                {keywords.map(name => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={filters.indexOf(name) > -1} color="primary" />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <FormControlLabel
              label={
                <Box display="flex" flexDirection="row" flexWrap="nowrap" alignItems="center">
                  <IconButton
                    size="small"
                    onClick={e => setPopper(!popper ? e.currentTarget : null)}
                  >
                    <InfoIcon color="disabled" />
                  </IconButton>
                  <Box display="flex" flexDirection="row" flexWrap="nowrap" alignItems="center">
                    <Typography className={clsx(classes.label, classes.manier)}>
                      {t('temples.legend').toUpperCase()}
                    </Typography>
                  </Box>
                </Box>
              }
              control={
                <Switch color="primary" checked={legend} onChange={() => toggle('legend')} />
              }
              labelPlacement="start"
              className={classes.tool}
            />
            <FormControlLabel
              label={
                <Typography className={clsx(classes.label, classes.manier)}>
                  {t('temples.numbers').toUpperCase()}
                </Typography>
              }
              control={
                <Switch color="primary" checked={numbers} onChange={() => toggle('numbers')} />
              }
              labelPlacement="start"
              className={classes.tool}
            />
            <FormControlLabel
              label={
                <Typography className={clsx(classes.label, classes.manier)}>
                  {t('temples.images').toUpperCase()}
                </Typography>
              }
              control={
                <Switch color="primary" checked={images} onChange={() => toggle('images')} />
              }
              labelPlacement="start"
              className={classes.tool}
            />
          </Box>
        </Fade>
      </Collapse>
      <Popper
        open={Boolean(popper)}
        anchorEl={popper}
        placement="bottom-end"
        disablePortal
        transition
      >
        <Paper className={classes.legend}>
          <Typography align="center" color="textSecondary" gutterBottom>
            {t('temples.state')}
          </Typography>
          {states.map(state => (
            <Typography key={state.text_name} align="center">
              <b style={{color: state.text_color}}>{state.text_name}</b>
            </Typography>
          ))}
        </Paper>
      </Popper>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  features: {
    '& > p': {
      color: props => props.open && theme.palette.primary.main,
    },
  },
  legend: {
    margin: theme.spacing(),
    padding: theme.spacing(),
  },
  select: {
    width: '128px',
  },
  tool: {
    minHeight: theme.spacing(6),
    marginLeft: theme.spacing(6),
  },
  label: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
  },
}));

export default Filters;
