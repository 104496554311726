import React, {useState, useEffect} from 'react';
import {useTheme} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import animateScrollTo from 'animated-scroll-to';

import {borderB, useGlobalStyles} from './utils';

const SubNav = ({pages}) => {
  const theme = useTheme();
  const [value, setValue] = useState(false);
  const classes = useGlobalStyles();

  useEffect(() => {
    const watchScroll = () => {
      const page = pages.findIndex(page => {
        const element = document.querySelector(`#${page.name}`).getBoundingClientRect();
        return element.y < theme.spacing(10) && element.y + element.height > theme.spacing(10);
      });
      if (page !== value) setValue(page !== -1 ? page : false);
    };

    document.addEventListener('scroll', watchScroll);
    return () => document.removeEventListener('scroll', watchScroll);
  }, []); // eslint-disable-line

  const handleChange = (_, newValue) => {
    // + 2 : pixel perfect trick to allow top page text divider to be hidden
    animateScrollTo(document.querySelector(`#${pages[newValue].name}`), {
      verticalOffset: -theme.spacing(10) + 2,
      minDuration: 800,
    });
  };

  // style={{transform: 'translateZ(1px)'}} : fix a glitchy sticky element on Safari desktop
  return (
    <Box
      py={2}
      px={{xs: 2, md: 12}}
      position="sticky"
      top={0}
      zIndex={10}
      height={theme.spacing(10)}
      style={{transform: 'translateZ(1px)'}}
      bgcolor="background.default"
      displayPrint="none"
      className={classes.fadein}
      {...borderB}
    >
      <Tabs
        variant="scrollable"
        scrollButtons="off"
        textColor="primary"
        value={value}
        onChange={handleChange}
      >
        {pages.map(page => (
          <Tab
            key={page.path}
            label={page.title.toUpperCase()}
            disableRipple
            disableFocusRipple
            tabIndex="0"
            focusVisibleClassName={classes.focus}
            className={classes.manier}
          />
        ))}
      </Tabs>
    </Box>
  );
};

export default SubNav;
