import React, {useEffect} from 'react';
import Box from '@material-ui/core/Box';
import {useLocation, matchPath} from 'react-router-dom';

import useStore from './useStore';
import Spotlight from './Spotlight';
import Logo from './Logo';
import Nav from './Nav';
import Footer from './Footer';
import {borderB, useGlobalStyles} from './utils';

const Layout = ({datas, children}) => {
  const {pathname} = useLocation();
  const classes = useGlobalStyles();
  const hideMenus = useStore(s => s.hideMenus);
  const set = useStore(s => s.set);

  const notRoot = matchPath(pathname, {path: '/:id'});

  useEffect(() => {
    set({hideMenus: false});
  }, [pathname, set]);

  if (!datas) return null;

  return (
    <>
      <Box
        minWidth={320}
        minHeight="100vh"
        display="flex"
        flexDirection="column"
        className={classes.fadein}
      >
        {!notRoot && (
          <Spotlight
            title={datas.text_spotlight_title}
            subtitle={datas.text_spotlight_subtitle}
            link={datas.page_link}
          />
        )}
        <Box
          component="nav"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          pb={4}
        >
          <Box width={1} pl={{xs: 2, md: 12}} pr={4}>
            {notRoot && (
              <Box pt={2}>
                <Logo img={datas.image_logo[0]} />
              </Box>
            )}
          </Box>
          {!hideMenus && <Nav pages={datas.children} border={notRoot} />}
        </Box>
        {notRoot && <Box {...borderB} displayPrint="none" />}
        <Box
          component="main"
          position="relative"
          flexGrow={1}
          width={1}
          {...(notRoot && {pb: 17})}
          {...(notRoot && borderB)}
        >
          {children}
        </Box>
      </Box>
      {notRoot && !hideMenus && (
        <Box component="footer" minWidth={320}>
          <Footer text={datas.text_footer} images={datas.image_footer} />
        </Box>
      )}
    </>
  );
};

export default Layout;
