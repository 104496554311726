import React, {useState, useEffect} from 'react';
import Box from '@material-ui/core/Box';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import {useTranslation} from 'react-i18next';
import {Switch as Routes, Route, useHistory} from 'react-router-dom';

import NoMatch from './NoMatch';
import {read, getComponent, useGlobalStyles} from './utils';

const Posts = page => {
  const {path} = page;
  const {t} = useTranslation();
  const history = useHistory();
  const [datas, setDatas] = useState();
  const classes = useGlobalStyles();

  useEffect(() => {
    (async () => setDatas(await read(`${path}?children=true`)))();
  }, []); // eslint-disable-line

  if (!datas) return null;
  const title = datas.title;
  const pages = datas.children;

  return (
    <Routes>
      <Route path={path} exact>
        <Box pt={10} px={{xs: 2, md: 12}} className={classes.fadein}>
          <Box pb={4}>
            <Typography variant="h6">{title}</Typography>
          </Box>
          {pages?.map(page => (
            <Box key={page.name} width={1} maxWidth={800}>
              <Box pb={4}>
                <Typography gutterBottom>{page.date_post}</Typography>
                <ButtonBase
                  component="a"
                  disableRipple
                  href={page.path}
                  onClick={e => {
                    if (e.metaKey === false) {
                      e.preventDefault();
                      history.push(page.path);
                    }
                  }}
                  focusVisibleClassName={classes.focus}
                  className={classes.hover}
                >
                  <Typography variant="h4">{page.title}</Typography>
                </ButtonBase>
              </Box>
              <Box className={classes.markdown}>
                <hr />
              </Box>
            </Box>
          ))}
          {!pages && <Typography>{t('main.nopost')}</Typography>}
        </Box>
      </Route>
      {pages.map(page => (
        <Route key={page.path} path={page.path} exact>
          {getComponent(page)}
        </Route>
      ))}
      <Route path="*">
        <NoMatch />
      </Route>
    </Routes>
  );
};

export default Posts;
