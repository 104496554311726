import React, {useState, useEffect} from 'react';
import {ThemeProvider} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import {BrowserRouter, Switch as Routes, Route} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import Layout from './Layout';
import Home from './Home';
import NoMatch from './NoMatch';
import {read, getComponent} from './utils';
import theme from './theme';

const App = () => {
  const {t} = useTranslation();
  const [datas, setDatas] = useState();

  useEffect(() => {
    // get home page datas (home '/' page id = 1)
    (async () => setDatas(await read('/1')))();
  }, []);

  useEffect(() => {
    if (datas?.text_name) document.title = datas.text_name;
  }, [datas]);

  if (!datas) return null;
  if (datas === 'protected') return <p style={{margin: 0, padding: 16}}>{t`main.protected`}</p>;

  const pages = datas.children;

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <CssBaseline />
        <Layout datas={datas}>
          <Routes>
            <Route path="/" exact>
              <Home datas={datas} />
            </Route>
            {pages.map(page => (
              <Route key={page.path} path={page.path}>
                {getComponent(page)}
              </Route>
            ))}
            <Route path="*">
              <NoMatch />
            </Route>
          </Routes>
        </Layout>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
