import React from 'react';
import Box from '@material-ui/core/Box';
import {useTheme} from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import {useTranslation} from 'react-i18next';

import useStore from './useStore';
import useWindowSize from './useWindowSize';
import {useGlobalStyles} from './utils';

const Footer = ({text, images}) => {
  const {t} = useTranslation();
  const size = useWindowSize();
  const theme = useTheme();
  const height = size.height - theme.spacing(17);
  const map = useStore(s => s.map);
  const toggle = useStore(s => s.toggle);
  const classes = useGlobalStyles();

  return (
    <Box
      width={1}
      minHeight={height}
      pt={4}
      pb={{xs: 4, md: 8}}
      px={{xs: 2, md: 12}}
      display="flex"
      flexDirection={{xs: 'column', md: 'row'}}
      justifyContent="space-between"
      position="relative"
      zIndex={10}
    >
      <Box
        position="absolute"
        top={0}
        left={0}
        width={1}
        height={1}
        display="flex"
        justifyContent="center"
        alignItems="center"
        zIndex={-10}
      >
        <Box
          component="img"
          maxHeight={0.5}
          maxWidth={1}
          width={0.7}
          mb={4}
          sx={{opacity: 0.7}}
          alt={images[1]?.origin.description}
          src={images[1]?.origin.httpUrl}
        />
        <Box
          position="absolute"
          width={1}
          height={1}
          style={{
            background: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)',
          }}
        />
      </Box>
      <Box display="flex" alignItems={{xs: 'flex-start', md: 'flex-end'}}>
        <Box
          component="img"
          width={{xs: 288, md: 440}}
          mb={4}
          alt={images[0]?.origin.description}
          src={images[0]?.origin.httpUrl}
        />
      </Box>
      <Box display="flex" flexDirection="column" displayPrint="none">
        <Box flexGrow={1} pt={4} textAlign={{xs: 'left', md: 'right'}}>
          <FormControlLabel
            label={t('main.map')}
            control={
              <Switch size="small" color="secondary" checked={map} onChange={() => toggle('map')} />
            }
          />
        </Box>
        <Box
          pt={4}
          textAlign={{xs: 'left', md: 'right'}}
          dangerouslySetInnerHTML={{__html: text.formatted}}
          className={classes.markdown}
        />
      </Box>
    </Box>
  );
};

export default Footer;
